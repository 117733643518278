import React from "react";
import { Col, Row } from "reactstrap";
import moment from "moment";
import { SuccessIcon, ErrorCircle } from "../../../../commons/components/Icons";
import { isMobile } from "../../../../utils/helpers";
import { stat } from "fs";

interface ConfirmationProps {
  setShowConfirmation?: any;
  deliveryDetails?: any;
  status: string | undefined;
  orderDate?: string | undefined;
  toggleTnc?: any;
}

const Confirmation: React.FC<ConfirmationProps> = ({
  setShowConfirmation,
  deliveryDetails,
  status,
  orderDate,
  toggleTnc,
}) => {
  const makeHeader = () => {
    switch (status) {
      case "LINK_CANCELLED":
        return (
          <div className="d-flex justify-content-center align-items-center flex-column">
            <div className="h6 text-black">Oh No!</div>
            <div className="text-black mt-4">Your gift is cancelled!</div>
            <div className="text-color-ink-light text-center">
              Your reward order has been cancelled by the sender. Details has
              been sent to your email address and mobile number.
            </div>
          </div>
        );
      case "DELIVERED":
        return (
          <div className="d-flex justify-content-center align-items-center flex-column">
            <img src="/img/icons/hurray-ico.svg" />
            <div className="text-black mt-4">Your gift is here!</div>
            <div className="text-color-ink-light text-center">
              Your reward order is delivered. Details has been sent to your
              email address and mobile number.
            </div>
          </div>
        );
      case "PENDING":
        return (
          <div className="d-flex justify-content-center align-items-center flex-column">
            <img src="/img/icons/hurray-ico.svg" />
            <div className="text-black mt-4">Your gift is on its way!</div>
            <div className="text-color-ink-light text-center">
              Your gift order is confirmed. Details will be sent to your email
              address and mobile number within 24 hours.
            </div>
          </div>
        );
      case "CONFIRMATION":
        return (
          <Row className="d-flex pb-2 flex-row" style={{ marginTop: "-30px" }}>
            <div>
              <a
                onClick={() => {
                  setShowConfirmation(false);
                }}
              >
                <img src="/img/icons/back-arrow.svg" alt="backBtnIcon" />
              </a>
            </div>
            <div className="w-75 ml-3">
              <>
                <Row>
                  <Col className="h6 mb-0">Collect your gift</Col>
                </Row>
                <Row>
                  <Col className="text-color-ink-light">
                    Confirm your delivery details to collect your gift.
                  </Col>
                </Row>
              </>
            </div>
          </Row>
        );
      default:
        return null;
    }
  };

  const makeDateComponent = () => {
    switch (status) {
      case "LINK_CANCELLED":
        return (
          <>
            <Col className={`px-0 mb-0 d-flex align-items-center`}>
              <div>
                <ErrorCircle />
              </div>
              <Col>
                <div className="h6 mb-0">Order Cancelled!</div>
                <div className="text-color-ink-light">
                  {orderDate
                    ? moment(orderDate).format("MMMM D, YYYY")
                    : moment().format("MMMM D, YYYY")}
                </div>
              </Col>
            </Col>
          </>
        );
      case "DELIVERED":
        return (
          <>
            <Col className="px-0 mb-0 d-flex">
              <SuccessIcon />
              <Col>
                <div className="h6 mb-0">Order Delivered!</div>
                <div className="text-color-ink-light">
                  {moment(orderDate).format("MMMM D, YYYY")}
                </div>
              </Col>
            </Col>
          </>
        );
      case "PENDING":
        return (
          <>
            <Col className="px-0 mb-0 d-flex">
              <SuccessIcon />
              <Col>
                <div className="h6 mb-0">Order Confirmed!</div>
                <div className="text-color-ink-light">
                  {orderDate
                    ? moment(orderDate).format("MMMM D, YYYY")
                    : moment().format("MMMM D, YYYY")}
                </div>
              </Col>
            </Col>
            <Col
              className={`px-0 w-75 d-flex flex-column ${
                isMobile() ? "align-items-start mt-3" : "align-items-end"
              }`}
            >
              <div className="text-color-ink-light h6 mb-0">
                Expected Delivery
              </div>
              <div className="date">{`${moment()
                .add(3, "days")
                .format("MMMM D, YYYY")}`}</div>
            </Col>
          </>
        );
      case "CONFIRMATION":
        return (
          <>
            <Col className="h6 px-0 mb-0">Expected Delivery</Col>
            <Col
              className={`date px-0 ${isMobile() ? "w-100 mt-2" : "w-75"}`}
            >{`${moment().add(3, "days").format("MMMM D, YYYY")} - ${moment()
              .add(7, "days")
              .format("MMMM D, YYYY")}`}</Col>
          </>
        );
      default:
        return null;
    }
  };
  return (
    <div
      className="d-flex flex-column justify-content-start"
      style={{
        gap: "24px",
        padding: status === "CONFIRMATION" ? "0" : "16px 24px",
      }}
    >
      {makeHeader()}
      <Row
        className={`d-flex ${
          isMobile() ? "align-items-start" : "align-items-center"
        } light-border w-100 ${isMobile() ? "flex-column" : "flex-row"}`}
      >
        {makeDateComponent()}
      </Row>
      {status === "LINK_CANCELLED" ||
      Object.keys(deliveryDetails).length === 0 ? null : (
        <Row
          className="light-border w-100 d-flex flex-column"
          style={{ gap: "12px" }}
        >
          <Row className="h6 mb-0 mx-0">Delivering To</Row>
          <div
            className="text-color-ink-light d-flex flex-column"
            style={{ gap: "12px" }}
          >
            <div>
              <div>{`${
                deliveryDetails.firstname || deliveryDetails.first_name || ""
              } ${
                deliveryDetails.lastname || deliveryDetails.last_name || ""
              }`}</div>
              <div>{`${
                (deliveryDetails.phoneCode &&
                  deliveryDetails.phoneCode.phonecode) ||
                ""
              } ${deliveryDetails.contact_no || ""}`}</div>
            </div>
            <div>
              <div>{`${deliveryDetails.address_1 || ""}`}</div>
              <div>{`${deliveryDetails.address_2 || ""}`}</div>
              <div>{`${deliveryDetails.city || ""}, ${
                deliveryDetails.zone || ""
              }`}</div>
              <div>{`${deliveryDetails.country || ""}, ${
                deliveryDetails.postcode || ""
              }`}</div>
            </div>
          </div>
          <Row></Row>
        </Row>
      )}
      <div className="text-color-ink-light text-center">
        For any queries, please write back to us at cs@xoxoday.com.{" "}
      </div>
      {status === "Confirmation" ? (
        <Col className=" tnc-link">
          <div onClick={toggleTnc} className="cursor-pointer">
            Terms & conditions
          </div>{" "}
          |{" "}
          <div onClick={toggleTnc} className="cursor-pointer">
            How to use
          </div>
        </Col>
      ) : null}
    </div>
  );
};

export default Confirmation;
