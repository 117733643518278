import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  Input,
  Button,
} from "reactstrap";
import { toast } from "react-toastify";
import MetaTags from "react-meta-tags";
import MediaQuery from "react-responsive";
// import { CLOUDINARY_URL } from '../../../commons/constants'
import { stripHtml } from "../../../utils/helpers";
import ApiService from "../../../api/api.service";
import ButtonLoader from "../../../commons/components/Loader/ButtonLoader";
import TncBack from "../components/Cards/TncBack";
import "./modal.scss";
import moment from "moment";
import { phone_codes } from "../../../utils/locales";

import {
  checkGivenEmailIsValid,
  checkGivenMobileNumberIsValid,
} from "../../../utils/globals";
import Select from "react-select";
import axios from "axios";
import { Turnstile } from "@plum-xoxo/turnstile_captcha";
import { LoadingIcon } from "../../../commons/components/Icons";
import { makeRequestAuth } from "../../../utils/globals";
import { MOBILE_NO_ERROR } from "../../../commons/constants/app.constants";
import config from "../../../../config/config.production.json";
import Confirmation from "./Cards/Confirmation";
const CLOUDINARY_URL = process.env.REACT_APP_CLOUDINARY_URL;
const TURNSTILE_SITE_KEY = config.turnstileSiteKey;

const customStyles = {
  control: (base: any) => ({
    ...base,
    height: 36,
    minHeight: 36,
    border: "1px solid #BAC7D5",
  }),
};

interface Props {
  modalStatus?: boolean;
  handleModal: Function;
  data?: any;
  linkCode?: String;
  code: string;
  pin: string;
  orderId: number;
  linkId: number;
  validity_date: string;
  apiMessage?: string;
  campaign?: any;
  client_name?: string;
  company_logo?: string;
  voucher_id?: number;
  voucherCampaignName?: string;
  errCode?: any;
  backClick?: any;
  redemption_email: string;
  redemption_phone: string;
  redemption_phone_code: string;
  redemption_check: boolean;
  authenticate_email: boolean;
  maskedEmail: string;
  authenticate_phone: boolean;
  maskedPhone: string;
  isGvLink: boolean;
  onlyXoxoCode: boolean;
  showCts: boolean;
  filter_group_id: any;
  deliveryDetails: any;
  orderDate?: any;
  merchandiseDeliverySuccess?: boolean;
}
interface State {
  voucher: boolean;
  modal?: boolean;
  code: string;
  pin: string;
  // orderId: Number;
  loading: boolean;
  copytext: boolean;
  sendMailStatus: String;
  inputField: string;
  inputType: String;
  inputValidate: boolean;
  showTnc: boolean;
  isMobileValid: boolean;
  isEmailValid: boolean;
  mobileNumber: string;
  phone_codes_ui?: any;
  isd_code: any;
  isd_code_object: any;
  errorMsg: string;
  error: boolean;
  email: string;
  disableBtn: boolean;
  renderTurnstileCapthca: boolean;
  redirectTimer: number;
  timerId: any;
}

const anchorStyle = {
  color: "#0172CB",
  cursor: "pointer",
};
class VoucherModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const phone_codes_ui =
      phone_codes &&
      phone_codes.map((item, index) => {
        let obj = item;
        obj.label = item.iso + " " + item.phonecode;
        obj.value = item.country_id;
        return obj;
      });

    const phonecodeDetails =
      this.props.redemption_phone && this.props.redemption_phone.split("-")[0]
        ? phone_codes_ui.find(
            (item: any) =>
              item.phonecode == this.props.redemption_phone.split("-")[0]
          )
        : {};

    this.state = {
      voucher: true,
      modal: this.props.modalStatus,
      code: "",
      pin: "",
      // orderId: 0,
      loading: false,
      copytext: false,
      sendMailStatus: "",
      inputField: "",
      inputType: "",
      inputValidate: false,
      showTnc: false,
      mobileNumber: "",
      isMobileValid: true,
      isEmailValid: true,
      phone_codes_ui: [],
      isd_code:
        (this.props.redemption_phone &&
          this.props.redemption_phone.split("-")[0]) ||
        "",
      isd_code_object: phonecodeDetails,
      errorMsg: "",
      error: false,
      email: "",
      disableBtn: true,
      renderTurnstileCapthca: false,
      redirectTimer: 5,
      timerId: "",
    };
  }

  validateInput = (event: any) => {
    let email = event.target.value;
    this.setState({ email });
  };

  toggleTnc = () => {
    this.setState(({ showTnc }) => {
      return {
        showTnc: !showTnc,
      };
    });
  };

  sendCopyMailValidate = (value: any) => {
    let intRegex = /[0-9 -()+]+$/;

    let emailReg = /\S+@\S+\.\S+/;

    if (value === "") {
      this.setState({
        inputType: "",
        inputValidate: false,
      });
    } else if (value && intRegex.test(value) === true) {
      this.setState({ inputType: "phone" });

      if (!value || value.length < 7 || value.length > 15) {
        this.setState({ inputValidate: false });
        return false;
      } else {
        this.setState({ inputValidate: true });
      }
    } else {
      this.setState({ inputType: "email" });
      if (emailReg.test(value) === true) {
        this.setState({ inputValidate: true });
      } else {
        this.setState({ inputValidate: false });
        return false;
      }
    }
    // this.sendCopy()
  };

  handleValidateInput = (field: string = "both") => {
    const { email, mobileNumber, isEmailValid, isMobileValid } = this.state;
    let validEmail = isEmailValid;
    let validMobile = isMobileValid;
    if ((field === "email" || field === "both") && email && email.length > 0) {
      validEmail = checkGivenEmailIsValid(email);
    }
    if (
      (field === "phone" || field === "both") &&
      mobileNumber &&
      mobileNumber.length > 0
    ) {
      validMobile = checkGivenMobileNumberIsValid(mobileNumber);
    }
    this.setState({ isEmailValid: validEmail, isMobileValid: validMobile });
    if (!isEmailValid || !isMobileValid) {
      this.setState({ disableBtn: true });
      return false;
    } else {
      this.setState({ disableBtn: false });
      return true;
    }
  };

  sendCopy = async ({ token = "" }) => {
    const { linkCode = "" }: any = this.props;
    const { email, mobileNumber, isd_code_object } = this.state;
    this.setState({ loading: true });
    if (!this.handleValidateInput("both")) {
      this.setState({ loading: false, renderTurnstileCapthca: false });
      return;
    } else {
      let payload = {
        link: linkCode,
        notification_input: this.state.inputField,
        orderId: this.props.orderId,
        linkId: this.props.linkId,
        input_email: email,
        input_phone: mobileNumber,
        input_phone_country_code: isd_code_object.phonecode,
        voucher_id: this.props.voucher_id,
        token,
      };

      const query = "stores.mutation.links_send_copy";

      const post_data = {
        query: query,
        tag: "stores",
        variables: {
          data: JSON.stringify(payload),
        },
      };

      const res = await makeRequestAuth({
        data: post_data,
        codeLength: linkCode.length,
      });
      const data =
        res && res.data && res.data.linksSendCopy ? res.data.linksSendCopy : {};
      if (data.success === 1) {
        this.setState({
          renderTurnstileCapthca: false,
          sendMailStatus: data.message,
          inputField: "",
          voucher: !this.state.voucher,
          modal: !this.state.modal,
        });
        setTimeout(() => {
          this.setState({
            sendMailStatus: "",
          });
        }, 3000);
        this.handleSendCopy();
        setTimeout(() => {
          this.setState({
            email: "",
            mobileNumber: "",
            isEmailValid: true,
            isMobileValid: true,
          });
        }, 2000);
      } else {
        this.setState({
          renderTurnstileCapthca: false,
          sendMailStatus: data.message,
          loading: false,
          email: "",
          mobileNumber: "",
          isEmailValid: true,
          isMobileValid: true,
        });
        setTimeout(() => {
          this.setState({ sendMailStatus: "" });
        }, 3000);
      }
      this.setState({ loading: false, renderTurnstileCapthca: false });
    }
  };

  copyToClipBoard = (copyText: "code" | "pin") => {
    let { code = "", pin = "" }: any = this.props;
    copyText === "code"
      ? navigator.clipboard.writeText(code)
      : navigator.clipboard.writeText(pin);

    this.setState({ copytext: true });
    // toast.success("Text Copied", { autoClose: 2000 });
    setTimeout(() => {
      this.setState({ copytext: false });
    }, 3000);
  };

  // toggle = () => this.props.handleModal();

  handleSendCopy = () => {
    if (this.state.loading === false) {
      this.setState({
        voucher: !this.state.voucher,
        modal: !this.state.modal,
      });
    }
  };

  handleHtml = (html: string) => {
    var temporalDivElement = document.createElement("span");
    temporalDivElement.innerHTML = html;
    html = temporalDivElement.textContent || temporalDivElement.innerText || "";
    return <span dangerouslySetInnerHTML={{ __html: html }} />;
  };

  getFormattedDate = (date: any) => {
    if (date) {
      try {
        let year = date.getFullYear() || "";
        let month = (1 + date.getMonth()).toString().padStart(2, "0");
        let day = date.getDate().toString().padStart(2, "0");
        let formattedDate = day + "-" + month + "-" + year;

        if (
          day &&
          month &&
          !Number.isNaN(day) &&
          !Number.isNaN(month) &&
          formattedDate
        ) {
          return formattedDate;
        }
      } catch (error) {
        console.log("e->", error);
      }
    }
  };

  voucherBlockData = (event: any) => {
    event.preventDefault();
  };

  handleMobileChange = (event: any) => {
    // this.setState({
    //   mobileNumber: event.target.value,
    // });
    if (event && event.target) {
      let mobileNumber = event.target.value;
      let reg = /^\d+$/;

      if (
        !(mobileNumber.length == 0 && mobileNumber == "") &&
        (!reg.test(mobileNumber) || parseInt(mobileNumber) == 0)
      ) {
        return;
      }
      if (mobileNumber && mobileNumber.length > 16) {
        return;
      }
      this.setState({
        mobileNumber: mobileNumber ? mobileNumber : "",
      });
    }
  };

  handleISDchange = (event: any) => {
    this.setState({
      isd_code_object: event,
      isd_code: event.phonecode,
      error: false,
      errorMsg: "",
    });
  };

  getDefaultIsd = async (phone_codes_ui: any) => {
    ApiService.getCountryIsoCode2().then((response) => {
      let countryCode = "IN";
      if (response && response.success && response.success === 1) {
        countryCode = response.country_code;
      }
      const phonecodeDetails = phone_codes_ui.find(
        (item: any) => item.iso == countryCode
      );
      this.setState({ isd_code_object: phonecodeDetails });
    });
  };

  componentDidMount() {
    const { code, pin } = this.props;
    const isLink = code ? code.startsWith("http") : false;
    if (isLink && !pin && !sessionStorage.getItem("redirect")) {
      let tId = setInterval(() => {
        this.setState({ redirectTimer: this.state.redirectTimer - 1 }, () => {
          if (this.state.redirectTimer === 0) {
            clearInterval(tId);
            const link = document.createElement("a");
            link.href = code;
            link.rel = "noreferrer";
            link.click();
            sessionStorage.setItem("redirect", "true");
          }
        });
      }, 1000);
      this.setState({ timerId: tId });
    } else {
      this.setState({ redirectTimer: 0 });
    }

    const phone_codes_ui =
      phone_codes &&
      phone_codes.map((item, index) => {
        let obj = item;
        obj.label = item.iso + " " + item.phonecode;
        obj.value = item.country_id;
        return obj;
      });
    this.setState({
      phone_codes_ui,
    });
    !this.props.redemption_phone && this.getDefaultIsd(phone_codes_ui);
  }
  componentWillUnmount() {
    clearInterval(this.state.timerId);
  }
  displayCode = (code: string, isLink: boolean) => {
    if (isLink) {
      return (
        <a className="gvlink exclude-translation" href={code} target="_blank">
          {code}
        </a>
      );
    }
    return code;
  };

  codeBox = (code = "", pin = "", copytext = false, isLink = false) => {
    return (
      <Row className="voucher-code ">
        <Col lg={10} sm={10} xs={10} md={10}>
          <Row>
            <Col className="fw-500 fs-12 text-color-ink-light" lg={12}>
              {code ? (isLink ? "VOUCHER LINK" : "CODE") : "ACTIVATION PIN"}
            </Col>
          </Row>
          <Row>
            <Col className="fs-16 fw-500" lg={12}>
              <span className="exclude-translation">
                {this.displayCode(code, isLink) || pin}
              </span>
            </Col>
          </Row>

          <div>
            {copytext ? (
              <div className="sendMailSuccess">
                <span>
                  <img src="/img/icons/right-tick.svg" alt="icon" /> Copied
                  successfully!
                </span>
              </div>
            ) : null}
          </div>
        </Col>
        <Col lg={2} md={2} sm={2} xs={2} className="copy-icon img-responsive">
          <img
            src="/img/icons/copy-code.svg"
            height={20}
            width={17}
            alt="copy"
            onClick={() =>
              code ? this.copyToClipBoard("code") : this.copyToClipBoard("pin")
            }
          />
        </Col>
      </Row>
    );
  };

  getRedeemedVoucherText = (isLink: boolean = false) => {
    const data = this.props.data || { name: "" };
    const campaign = this.props.campaign || { amount: 0, currency_code: "" };
    const heading = `Your ${data.name} voucher worth ${campaign.currency_code} ${campaign.amount} is here.`;
    // if (
    //   this.props.redemption_email &&
    //   this.props.data &&
    //   this.props.redemption_check
    // ) {
    //   const description = `We have sent an email containing your voucher details to `;
    //   return (
    //     <>
    //       <Row>
    //         <Col className="fs-16 fw-400 text-color-black">{heading}</Col>
    //       </Row>
    //       <Row>
    //         <Col className="text-color-ink-light">
    //           {description} <br /> {this.props.redemption_email}
    //         </Col>
    //       </Row>
    //     </>
    //   );
    // } else if (this.props.redemption_phone && this.props.redemption_check) {
    //   const description = `We have sent an SMS containing your voucher details to`;
    //   return (
    //     <>
    //       <Row>
    //         <Col className="fs-16 fw-400 text-color-black">{heading}</Col>
    //       </Row>
    //       <Row>
    //         <Col className="text-color-ink-light">
    //           {description} <br /> {this.props.redemption_phone_code}{" "}
    //           {this.props.redemption_phone}
    //         </Col>
    //       </Row>
    //     </>
    //   );
    // } else {
    return (
      <>
        {this.props.data.name !== "Xoxo Code" ? (
          <>
            {isLink
              ? `The code for ${this.props.data.name} voucher  can be redeemed in the link below. Please click on the link to access the code.`
              : `Your code for ${this.props.data.name} voucher is here.`}{" "}
            You can access this screen anytime by clicking the Collect Voucher
            link in the email you received.
          </>
        ) : (
          <div className="voucher-modal-text">
            <div className="title">
              {this.props.campaign && (
                <>
                  Your Xoxo Code voucher worth{" "}
                  {this.props.campaign.currency_code}{" "}
                  {this.props.campaign.amount} is here.
                </>
              )}

              {/* <div className="subtitle">
                We have sent an email address containing your voucher details to{" "}
                {this.props.redemption_email}
              </div> */}
            </div>
          </div>
        )}
      </>
    );
    // }
  };

  render() {
    const {
      data,
      code,
      pin,
      orderId,
      linkId,
      apiMessage,
      validity_date,
      campaign = {},
      client_name = "",
      voucher_id = "",
      voucherCampaignName = "",
      errCode = null,
      redemption_email,
      redemption_phone,
      redemption_phone_code,
      isGvLink = false,
      onlyXoxoCode = false,
      showCts = false,
      filter_group_id,
      merchandiseDeliverySuccess = false,
    } = this.props;
    const currencyCode = "INR";
    const {
      voucher,
      modal,
      loading,
      showTnc,
      sendMailStatus,
      copytext,
      mobileNumber,
      isMobileValid,
      isEmailValid,
      phone_codes_ui,
      isd_code_object,
      // disableBtn,
      email,
      renderTurnstileCapthca,
      redirectTimer,
    } = this.state;

    // var date = new Date(validity_date);
    // let formattedDate = this.getFormattedDate(date)

    let disableBtn = mobileNumber.length === 0 && email.length === 0;
    let hasNoExpiry = validity_date === "0000-00-00";
    const isLink = code ? code.startsWith("http") : false;

    return (
      <>
        {campaign.name ? (
          <MetaTags>
            <title>
              {campaign.name} - {client_name ? client_name : ""}- Selected from{" "}
              {campaign.products_count} Brands
            </title>
          </MetaTags>
        ) : null}

        {filter_group_id === config.merch_filter_group_id &&
        merchandiseDeliverySuccess ? (
          <>
            <Modal
              isOpen={true}
              className="modal-wrapper error-modal"
              backdrop={false}
              style={{ padding: "24px" }}
            >
              <Confirmation
                deliveryDetails={this.props.deliveryDetails}
                status={"DELIVERED"}
                orderDate={this.props.orderDate}
              />
            </Modal>
          </>
        ) : (
          <div>
            <Modal
              isOpen={modal && !showTnc}
              className={`voucher-modal ${
                apiMessage ? "error-modal" : "other-modal"
              }  ${onlyXoxoCode && "voucher-modal-shadow"}`}
              fade={false}
              backdrop={!(onlyXoxoCode || isGvLink)}
            >
              <ModalBody className="text-center">
                {isLink && !pin && redirectTimer > 0
                  ? `We are redirecting you to the redemption page in....${redirectTimer}s`
                  : null}
                {isLink && !pin ? (
                  <div>
                    <a
                      style={anchorStyle}
                      href={code}
                      onClick={() => {
                        sessionStorage.setItem("redirect", "true");
                      }}
                    >
                      Click here
                    </a>{" "}
                    if not automatically redirected.
                  </div>
                ) : null}
                <Container>
                  <Row>
                    <Col>
                      {apiMessage ? null : (
                        <>
                          <h2 className="text-center inkLight hooray">
                            <img src="/img/icons/hurray-ico.svg" />
                          </h2>
                          <div className="pb-1">
                            {" "}
                            {linkId
                              ? `Order Reference ID: ${linkId}`
                              : orderId
                              ? `Order Reference ID: ${orderId}`
                              : ""}
                          </div>
                          <Row>
                            <Col
                              md={{
                                offset: 1,
                                size: 10,
                              }}
                              sm="12"
                            >
                              {this.getRedeemedVoucherText(isLink)}
                            </Col>
                          </Row>
                        </>
                      )}

                      {code ? (
                        <Row>
                          <Col
                            md={{
                              offset: 1,
                              size: 12,
                            }}
                            sm="12"
                          >
                            {this.codeBox(code, "", copytext, isLink)}
                          </Col>
                        </Row>
                      ) : null}

                      {pin ? (
                        <Row>
                          <Col
                            md={{
                              offset: 1,
                              size: 12,
                            }}
                            sm="12"
                          >
                            {this.codeBox("", pin, copytext, false)}{" "}
                          </Col>
                        </Row>
                      ) : null}

                      {voucher_id != "" && voucherCampaignName != "" ? (
                        <Row>
                          <Col
                            md={{
                              offset: 1,
                              size: 10,
                            }}
                            sm="12"
                            className="text-color-ink-light"
                          >
                            {hasNoExpiry ? (
                              `This ${
                                isLink ? "link" : "voucher"
                              } has no expiry date`
                            ) : (
                              <>
                                This {isLink ? "link" : "voucher"} is valid till{" "}
                                {moment(validity_date).format("LL")}.{" "}
                                {voucherCampaignName && (
                                  <>
                                    Use this code in{" "}
                                    <a
                                      className="xc_link blue-link"
                                      href={voucherCampaignName}
                                      target="_blank"
                                    >
                                      Plum Store
                                    </a>
                                  </>
                                )}
                              </>
                            )}
                          </Col>
                        </Row>
                      ) : validity_date && code ? (
                        <Row>
                          <Col
                            md={{
                              offset: 1,
                              size: 10,
                            }}
                            sm="12"
                            className="text-color-ink-light"
                          >
                            {hasNoExpiry ? (
                              `This ${
                                isLink ? "link" : "voucher"
                              } has no expiry date`
                            ) : (
                              <>
                                This voucher is valid till{" "}
                                {moment(validity_date).format("LL")}
                              </>
                            )}
                          </Col>
                        </Row>
                      ) : null}

                      {!onlyXoxoCode &&
                        (sendMailStatus ? (
                          <div className="sendMailSuccess">
                            <span>
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>{" "}
                              {sendMailStatus}
                            </span>
                          </div>
                        ) : !isGvLink && code ? (
                          <Row>
                            <Col
                              md={{
                                offset: 1,
                                size: 10,
                              }}
                              sm="12"
                            >
                              <div className="button-footer text-center">
                                <button
                                  className="btn btn-filled send-copy-btn"
                                  onClick={this.handleSendCopy}
                                >
                                  <svg
                                    width="21"
                                    className="mr-1"
                                    height="20"
                                    viewBox="0 0 21 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M4.72086 11.102L10.7908 10.5145L10.7883 10.5095C10.9154 10.497 11.0334 10.4376 11.1191 10.3429C11.2049 10.2482 11.2524 10.125 11.2524 9.99726C11.2524 9.86951 11.2049 9.74632 11.1191 9.65164C11.0334 9.55697 10.9154 9.49757 10.7883 9.48499L4.73075 8.89758C4.45074 8.87043 4.21419 8.67828 4.13022 8.40978L2.54954 3.35527C2.49933 3.19627 2.49688 3.02603 2.54249 2.86564C2.58809 2.70525 2.67975 2.56177 2.80611 2.45297C2.93246 2.34417 3.08797 2.27484 3.25335 2.25355C3.41873 2.23227 3.58672 2.25998 3.7365 2.33324L18.0425 9.27009C18.1797 9.33701 18.2953 9.44113 18.3762 9.57059C18.4571 9.70004 18.5 9.84961 18.5 10.0023C18.5 10.1549 18.4571 10.3045 18.3762 10.4339C18.2953 10.5634 18.1797 10.6675 18.0425 10.7344L3.72651 17.6663C3.57672 17.7395 3.40873 17.7673 3.24335 17.746C3.07797 17.7247 2.92247 17.6553 2.79611 17.5465C2.66975 17.4377 2.5781 17.2943 2.53249 17.1339C2.48688 16.9735 2.48934 16.8033 2.53955 16.6442L4.1202 11.5898C4.20418 11.3213 4.4408 11.1291 4.72086 11.102Z"
                                      fill="#252A31"
                                    />
                                  </svg>
                                  {loading ? (
                                    <ButtonLoader />
                                  ) : (
                                    " Send me a copy "
                                  )}{" "}
                                  {/* first modal button */}
                                </button>
                              </div>
                            </Col>
                          </Row>
                        ) : null)}
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              {!apiMessage ? (
                <div className="text-center mb-3 inkLight justify-content-center">
                  <span
                    className="tnc-link cursor-pointer"
                    onClick={this.toggleTnc}
                    style={{ fontSize: "12px" }}
                  >
                    Terms & conditions | How to use
                  </span>
                </div>
              ) : null}
            </Modal>

            <Container className="main">
              {/* 2nd modal */}
              <Modal
                isOpen={!voucher}
                fade={false}
                modalClassName="modal-send-code"
                wrapClassName="other-modal"
                className="modal-wrapper other-modal"
                //send-code-wrap
              >
                <ModalBody className="text-center ">
                  <Row>
                    <Col lg="1" className="text-left">
                      <span onClick={this.handleSendCopy} className="link">
                        <img src="/img/icons/back-arrow.svg" alt="icon" />
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mt-3" lg="12">
                      <h3 className="inkNormal text-center mb-2">
                        Enter the details and get the copy of the voucher code
                      </h3>
                    </Col>

                    <Col lg="12">
                      <p className="inkLight text-center">
                        Enter your email address or phone number and get the
                        voucher code sent to you.
                      </p>
                    </Col>
                  </Row>

                  <form
                    role="form"
                    className="voucher__block-Form"
                    onSubmit={this.voucherBlockData}
                    noValidate
                  >
                    <Row className="voucher__block">
                      <Col className="my-1 text-left" lg="12">
                        <label className="-label">
                          Email and/or Mobile number
                        </label>
                      </Col>
                      <Col className="my-2 voucher__input_block" lg="12">
                        <div className="voucher__input">
                          <input
                            type="email"
                            value={email || ""}
                            onChange={this.validateInput}
                            onBlur={() => this.handleValidateInput("email")}
                            placeholder="Email"
                            id="email"
                            className={
                              !isEmailValid
                                ? "input-send-copy inputErrorOnly  error"
                                : "input-send-copy"
                            }
                          />
                        </div>
                      </Col>

                      {!isEmailValid && !loading ? (
                        <Col lg="12">
                          <p className="-phnError mb-1 py-0">
                            Please enter a valid email
                          </p>
                        </Col>
                      ) : null}

                      <Col className="my-2 voucher__input_block" lg="12">
                        <div className="voucher__input d-flex">
                          <Select
                            options={phone_codes_ui}
                            value={isd_code_object}
                            isSearchable={true}
                            onChange={(event) => {
                              this.handleISDchange(event);
                            }}
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            placeholder=""
                            // placeholder="+91"
                            noOptionsMessage={() => {
                              return "No Code";
                            }}
                            className="react-select -countryCode mr-2"
                            styles={customStyles}
                            // defaultValue={phone_codes[99].phonecode}
                          />
                          {/* type="number" */}
                          <Input
                            placeholder="Mobile number"
                            onChange={(e) => this.handleMobileChange(e)}
                            onBlur={() => this.handleValidateInput("phone")}
                            value={mobileNumber || ""}
                            className={
                              !isMobileValid
                                ? "inputErrorOnly error"
                                : "inputErrorOnly"
                            }
                          />
                        </div>
                      </Col>

                      {!isMobileValid && !loading ? (
                        <Col lg="12">
                          <p className="-phnError mb-1 py-0">
                            {MOBILE_NO_ERROR}
                          </p>
                        </Col>
                      ) : null}
                      {/*  */}
                      <Col lg="12">
                        {renderTurnstileCapthca ? (
                          <Turnstile
                            className="text-center"
                            siteKey={TURNSTILE_SITE_KEY}
                            onSuccess={(token: any) => this.sendCopy({ token })}
                            options={{ theme: "light" }}
                            style={{ width: "100%" }}
                          />
                        ) : (
                          <button
                            className={`btn btn-filled my-3 btn-send py-2 px-4`}
                            onClick={() =>
                              showCts
                                ? this.setState({
                                    renderTurnstileCapthca: true,
                                  })
                                : this.sendCopy({})
                            }
                            disabled={disableBtn}
                          >
                            {loading ? <ButtonLoader /> : "Send"}
                          </button>
                        )}
                      </Col>
                    </Row>
                  </form>
                </ModalBody>
              </Modal>
            </Container>

            {showTnc ? (
              <div className="tnc-wrap">
                <TncBack
                  showTnc={showTnc}
                  toggleTnc={this.toggleTnc}
                  data={data}
                  overlay={!onlyXoxoCode}
                />
              </div>
            ) : null}
          </div>
        )}
      </>
    );
  }
}

export default VoucherModal;
